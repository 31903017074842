import List from "~/components/list";
import GenericBarChart from "~/routes/management.scoreboard/chart-generic-bar";

type Props = {
  className?: string;
  height?: string;
};
export default function RecentCollectionsGauge({
  className,
  height = "251px"
}: Props) {
  return (
    <GenericBarChart
      className={className}
      barLabel="Collected"
      chartLabel="Collections"
      goalField="Revenue (Collected): Goal"
      actualField="Revenue (Collected): Actual"
      targetField="Revenue (Collected): Target"
      format="Accounting"
      height={height}
      tooltip={
        <List single>
          <li>Actual: Net Decrease in A/R (includes applying retainers)</li>
          <li>Target: Goal as of the end of the current business day</li>
          <li>Goal: From Accounts Receivable KPIs</li>
        </List>
      }
    />
  );
}
